import { cx } from 'class-variance-authority';
import React from 'react';

type FormTextareaProps = {
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  defaultValue?: string;
  error?: boolean;
};
const FormTextarea = React.forwardRef<HTMLTextAreaElement, FormTextareaProps>(
  ({ placeholder, error, ...rest }, ref) => {
    return (
      <textarea
        className={cx([
          'focus:outline-primary block min-h-[15rem] w-full resize-none rounded-[10px] bg-brand-darker-white p-[1.6rem] focus:p-[1.4rem]',
          error ? 'outline outline-2 outline-brand-red' : 'outline-none',
        ])}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      />
    );
  }
);

FormTextarea.displayName = 'FormTextarea';

export default FormTextarea;
