import { Brand, BrandRoute } from '@util/types/brands';
import { BrandFilter } from '@util/firestore/products/';

export const TOP_BRANDS = [
  'Apple',
  'ARRI',
  'Benq',
  'Canon',
  'DJI',
  'Fujifilm',
  'GoPro',
  'Huawei',
  'Kodak',
  'Leica',
  'Nubear',
  'Nikon',
  'Panasonic',
  'RED',
  'Samsung',
  'SanDisk',
  'Sigma',
  'Sony',
] as const;

// Add more brands here
export const BRANDS = [...TOP_BRANDS];

export const BRAND_ROUTES = [
  'apple-49',
  'arri-28',
  'benq-137',
  'canon-7',
  'dji-40',
  'fujifilm-13',
  'gopro-16',
  'huawei-318',
  'kodak-29',
  'leica-17',
  'nu-bear-138',
  'nikon-8',
  'panasonic-9',
  'red-digital-cinema-12',
  'samsung-27',
  'sandisk-70',
  'sigma-14',
  'sony-11',
] as const;

export const ROUTE_BRAND_MAP: Record<BrandRoute, Brand> = {
  'canon-7': 'Canon',
  'dji-40': 'DJI',
  'arri-28': 'ARRI',
  'benq-137': 'Benq',
  'fujifilm-13': 'Fujifilm',
  'gopro-16': 'GoPro',
  'huawei-318': 'Huawei',
  'kodak-29': 'Kodak',
  'leica-17': 'Leica',
  'nu-bear-138': 'Nubear',
  'nikon-8': 'Nikon',
  'panasonic-9': 'Panasonic',
  'red-digital-cinema-12': 'RED',
  'sony-11': 'Sony',
  'samsung-27': 'Samsung',
  'sandisk-70': 'SanDisk',
  'sigma-14': 'Sigma',
  'apple-49': 'Apple',
};

export const FILTER_BRAND_MAP: Record<BrandFilter, Brand> = {
  apple: 'Apple',
  arri: 'ARRI',
  canon: 'Canon',
  dji: 'DJI',
  benq: 'Benq',
  fujifilm: 'Fujifilm',
  gopro: 'GoPro',
  huawei: 'Huawei',
  nubear: 'Nubear',
  kodak: 'Kodak',
  leica: 'Leica',
  nikon: 'Nikon',
  panasonic: 'Panasonic',
  red: 'RED',
  sony: 'Sony',
  samsung: 'Samsung',
  sandisk: 'SanDisk',
  sigma: 'Sigma',
};



export const BRAND_ROUTE_MAP: Record<Brand, BrandRoute> = {
  Apple: 'apple-49',
  ARRI: 'arri-28',
  Benq: 'benq-137',
  Canon: 'canon-7',
  DJI: 'dji-40',
  Fujifilm: 'fujifilm-13',
  GoPro: 'gopro-16',
  Huawei: 'huawei-318',
  Kodak: 'kodak-29',
  Leica: 'leica-17',
  Nubear: 'nu-bear-138',
  Nikon: 'nikon-8',
  Panasonic: 'panasonic-9',
  RED: 'red-digital-cinema-12',
  Samsung: 'samsung-27',
  SanDisk: 'sandisk-70',
  Sigma: 'sigma-14',
  Sony: 'sony-11',
};
