import ShoppingCart from '@c/ShoppingCart';
import useDebounce from '@util/hooks/useDebounce';
import { Cart, CartItem, cartSchema } from '@util/types/firestore/carts';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth } from './AuthContext';
import { useToastContext } from './ToastContext';
import { ProductDocument } from '@util/types/firestore/products';
import ItemAddedToast from '@c/toasts/ItemAdded';
import useRealTimeProducts from '@util/hooks/useRealtimeProducts';
import isEqual from 'deep-eql';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCartState, setCartState } from '@util/firestore/cart';
import { AddressDocument } from '@util/types/firestore/address';
import { europeCodes, southAmCodes } from '@util/types/locations';
import { Rate } from '@util/firestore/shipengine';
import { getTax } from '@util/firestore/taxjar';
import { Coupon } from '@util/firestore/stripe';
import ErrorToast from '@c/toasts/ErrorToast';
import { getProductById } from '@util/firestore/products';
import { logAddToCart } from '@util/analytics';

type ShoppingCartProviderProps = {
  children: ReactNode;
};
type ProductId = string;
type ProductIdToRateMap = Map<ProductId, Rate>;
type SellerId = string;
type SelectedShippingRates = Map<SellerId, ProductIdToRateMap>;
type ShoppingCartContext = {
  addCoupons: (coupons: Coupon[]) => void;
  canAddProductToCart: (productId: string) => boolean;
  cart: Cart;
  cartOpen: boolean;
  clearCart: () => void;
  coupons: Coupon[];
  decreaseCartQty: (id: string) => void;
  getDiscount: (total: number) => number;
  getItemCount: () => number;
  getItemQty: (id: string) => number;
  handleShippingRateSelected: (
    sellerId: string,
    productId: string,
    rate: Rate
  ) => void;
  increaseCartQty: (item: CartItem, dontOpenCart?: boolean) => void;
  realTimeProducts: ProductDocument[];
  removeCoupon: (coupon: Coupon) => void;
  removeFromCart: (id: string) => void;
  selectedShippingRates: SelectedShippingRates;
  setCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shippingSelectionComplete: boolean;
  taxInfo: {
    value?: number;
    itemValues?: Map<string, number>;
    isCalculating: boolean;
  };
  updateShippingAddress: (address: AddressDocument) => void;
  calculateTax: (items: CartItem[], shippingAddress: AddressDocument) => void;
};

const ShoppingCartContext = createContext({} as ShoppingCartContext);

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

export function calculateShipping(
  product: ProductDocument,
  address: AddressDocument
) {
  return product?.shipping_costs?.find((s) => {
    if (address.state_province === 'AK') return s.code === 'AK' ? s : false;
    if (address.state_province === 'HI') return s.code === 'HI' ? s : false;
    if (address.state_province === 'PR') return s.code === 'PR' ? s : false;
    if (s.code === address.country_code) return s;
    if (s.code === 'EU' && europeCodes.includes(address.country_code)) return s;
    if (s.code === 'SAM' && southAmCodes.includes(address.country_code))
      return s;
    if (s.code === 'OT') return s.cost;
    return false;
  });
}

function shippingSelectionComplete(
  cart: Cart,
  selectedRates: SelectedShippingRates
) {
  // every item in the cart must have a shipping rate selected
  return cart.items.every((item) => {
    if (item.is_flat_rate) return true;
    const found = selectedRates.get(item.seller_id)?.get(item.product_id);
    return !!found;
  });
}

const UPDATE_INTERVAL = 1000 * 3; // 3 seconds
const getDefaultCart = (uid?: string) => cartSchema.parse({ uid: uid ?? '' });

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [selectedShippingRates, setSelectedShippingRates] =
    useState<SelectedShippingRates>(new Map());
  const [shippingAddress, setShippingAddress] = useState<AddressDocument>();
  const [taxInfo, setTaxInfo] = useState<{
    value?: number;
    itemValues?: Map<string, number>;
    isCalculating: boolean;
  }>({
    isCalculating: false,
  });
  const { showToast } = useToastContext();
  const { userDoc } = useAuth();
  const [initialCart, setInitialCart] = useState<Cart | null>(null);
  const [cart, setCart] = useState<Cart>(() => getDefaultCart(userDoc?.uid));
  const { realTimeProducts } = useRealTimeProducts(cart.product_ids);
  const debouncedCart = useDebounce<Cart>(cart, UPDATE_INTERVAL);
  const [cartOpen, setCartOpen] = useState(false);
  const setCartMutation = useMutation({
    mutationFn: setCartState,
    onSuccess: (_, variables) => {
      setInitialCart(variables.newCartState);
    },
  });

  // #region DANGER ZONE
  useQuery({
    queryKey: ['cart', userDoc?.uid ?? ''],
    queryFn: () => getCartState({ uid: userDoc?.uid ?? '' }),
    enabled: !!userDoc?.uid, // disabled until the uid is available
    staleTime: Infinity, // stale time inifity to only fetch once
    refetchOnWindowFocus: 'always',
    onSuccess: async (data) => {
      if (data.results) {
        const firestoreCart = calcCart({ ...data.results });

        if (isEqual(firestoreCart, cart)) return;
        setCart(firestoreCart);
        setInitialCart(firestoreCart);
      } else {
        setCart(getDefaultCart(userDoc?.uid));
        setInitialCart(null);
      }
    },
    onError: () => {
      setCart(getDefaultCart(userDoc?.uid));
      setInitialCart(null);
    },
  });

  const shouldUpdateFirestore = useMemo(() => {
    // compare initial cart and debounced cart, but exclude the last_updated values
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { last_updated: lastUpdatedDebounced, ...restDebounce } =
      debouncedCart;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { last_updated: lastUpdatedInitial, ...restInitial } =
      initialCart ?? {};
    return !isEqual(restDebounce, restInitial);
  }, [debouncedCart, initialCart]);

  useEffect(() => {
    if (!debouncedCart?.uid) {
      setCart(getDefaultCart(userDoc?.uid));
      return;
    }
    if (shouldUpdateFirestore) {
      setCartMutation.mutateAsync({
        newCartState: { ...debouncedCart, last_updated: Date.now() },
      });
    }
    // this should only run after the debounce interval OR when the uid is updated,
    // don't fill out the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCart, userDoc?.uid]);

  // updates cart when shipping info changes
  useEffect(() => {
    const newCart = calcCart(cart);
    if (isEqual(newCart, cart)) return;
    setCart(newCart);

    if (
      !shippingAddress ||
      !newCart.items.length ||
      !shippingSelectionComplete(newCart, selectedShippingRates)
    ) {
      setTaxInfo({ isCalculating: false });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShippingRates, shippingAddress, coupons]);

  useEffect(() => {
    const firestoreCart = calcCart({ ...cart });
    if (isEqual(firestoreCart, cart)) return;
    setCart(firestoreCart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxInfo.value]);
  // #endregion DANGER ZONE

  // #region cart interactions
  function getItemCount() {
    const total = cart.items.reduce(
      (accumulator, cartItem) => accumulator + cartItem.qty,
      0
    );
    return total;
  }

  function getItemQty(id: string) {
    return cart.items.find((item) => item.product_id === id)?.qty || 0;
  }

  async function increaseCartQty(newItem: CartItem, dontOpenCart = false) {
    const productDoc = await getProductById(newItem.product_id);

    if (productDoc?.out_of_stock) {
      notifyError(0);
      return;
    }

    setCart((currCart) => {
      const currItems = currCart.items;
      if (
        currItems.find(
          (currItem) => currItem.product_id === newItem.product_id
        ) == null
      ) {
        // if the item does not exist in the cart
        notifyItemsAdded();
        logCart(newItem, productDoc);
        return calcCart({
          created: currCart.created,
          items: [...currItems, newItem],
          uid: currCart.uid,
        });
      } else {
        // if the item already exists in the cart
        let error = false;
        let stock = 0;
        const mappedItems = currItems.map((i) => {
          if (i.product_id === newItem.product_id) {
            const newQty = i.qty + newItem.qty;
            const newItemProductDoc = realTimeProducts.find(
              (p) => p.id === newItem.product_id
            );
            if (newItemProductDoc && newQty > newItemProductDoc.stock) {
              // if user tries to add more items than are in stock
              stock = newItemProductDoc.stock; // get the stock
              error = true;
              return i;
            }
            return { ...i, qty: i.qty + newItem.qty };
          } else {
            return i;
          }
        });

        error ? notifyError(stock) : notifyItemsAdded();
        return calcCart({
          created: currCart.created,
          items: mappedItems,
          uid: currCart.uid,
        });
      }
    });

    if (!cartOpen && !dontOpenCart) {
      setCartOpen(true);
    }
  }

  async function logCart(item: CartItem, product: ProductDocument | null) {
    try {
      if (product) {
        const total =
          (item.shipping_cost || 0) + item.product_cost * (item.qty || 1);
        await logAddToCart(product, total, userDoc?.uid ?? '');
      }
    } catch (e) {
      console.error(e);
    }
  }

  function decreaseCartQty(id: string) {
    setCart((currCart) => {
      const currItems = currCart.items;
      if (currItems.find((item) => item.product_id === id)?.qty === 1) {
        const filteredItems = currItems.filter(
          (item) => item.product_id !== id
        );
        return calcCart({
          created: currCart.created,
          items: filteredItems,
          uid: currCart.uid,
        });
      } else {
        const mappedItems = currItems.map((item) => {
          if (item.product_id === id) {
            return { ...item, qty: item.qty - 1 };
          } else {
            return item;
          }
        });
        return calcCart({
          created: currCart.created,
          items: mappedItems,
          uid: currCart.uid,
        });
      }
    });
  }

  function removeFromCart(id: string) {
    setCart((currCart) => {
      const filteredItems = currCart.items.filter(
        (item) => item.product_id !== id
      );
      return calcCart({
        created: currCart.created,
        items: filteredItems,
        uid: currCart.uid,
      });
    });
  }

  function addCoupons(coupons: Coupon[]) {
    setCoupons((p) => {
      const existingCouponIds = p.map((c) => c.id);
      const newCoupons = coupons.filter(
        (c) => !existingCouponIds.includes(c.id)
      );
      return [...p, ...newCoupons];
    });
  }

  function removeCoupon(coupon: Coupon) {
    setCoupons((p) => p.filter((c) => c.id !== coupon.id));
  }

  function handleShippingRateSelected(
    sellerId: string,
    productId: string,
    rate: Rate
  ) {
    setSelectedShippingRates((currRates) => {
      const existingSellerRates = currRates.get(sellerId);
      const newSellerRates = new Map(existingSellerRates);
      newSellerRates.set(productId, rate);
      return new Map(currRates.set(sellerId, newSellerRates));
    });
  }

  function updateShippingAddress(address: AddressDocument) {
    if (isEqual(address, shippingAddress)) return;
    setShippingAddress(address);
  }

  function clearCart() {
    setCart((prev) => {
      return {
        ...prev,
        items: [],
      };
    });
    setInitialCart(null);
    setCoupons([]);
    setSelectedShippingRates(new Map());
    setShippingAddress(undefined);
    setTaxInfo({ isCalculating: false });
  }

  function canAddProductToCart(productId: string) {
    const product = realTimeProducts.find((p) => p.id === productId);
    if (!product) return true; // if product is not found, assume it can be added
    const cartItem = cart.items.find((i) => i.product_id === productId);
    if (!cartItem) return true;
    return cartItem.qty < product.stock;
  }
  // #endregion cart interactions

  // #region helpers
  const calcFlatRateShipping = (
    productId: string,
    address: AddressDocument
  ) => {
    const foundProduct = realTimeProducts.find((r) => r.id === productId);
    if (!foundProduct) return;
    return calculateShipping(foundProduct, address);
  };

  const getShipping = (
    items: CartItem[],
    shippingAddress?: AddressDocument
  ) => {
    let shippingTotal = 0;
    const itemizedMap = new Map<string, number>();
    if (!shippingAddress) return { shippingTotal, itemizedMap };
    items.forEach((i) => {
      if (i.is_flat_rate) {
        const flatRate = calcFlatRateShipping(i.product_id, shippingAddress!);
        if (!flatRate)
          throw new Error(
            'Flat Rate Shipping Unavailable for product_id: ' + i.product_id
          );
        shippingTotal += flatRate.cost ?? 0;
        itemizedMap.set(i.product_id, flatRate.cost ?? 0);
      } else {
        const selectedRate = selectedShippingRates
          .get(i.seller_id)
          ?.get(i.product_id);
        if (selectedRate) {
          shippingTotal += selectedRate.total_amount;
          itemizedMap.set(i.product_id, selectedRate.total_amount);
        }
      }
    });
    return { shippingTotal, itemizedMap };
  };

  function getDiscount(total: number) {
    if (!coupons.length) return 0;
    // only support one coupon for now
    const coupon = coupons[0];
    if (coupon.amount_off !== null) {
      return coupon.amount_off;
    }
    if (coupon.percent_off !== null) {
      const percentOff = coupon.percent_off;
      return total * (percentOff / 100);
    }

    return 0;
  }

  function getCartItemsSubtotal(items: CartItem[]) {
    const total = items.reduce(
      (accumulator, cartItem) =>
        accumulator + cartItem.qty * cartItem.product_cost,
      0
    );
    const discount = getDiscount(total);
    return total - discount;
  }

  function calcCart({
    items,
    created,
    uid,
  }: {
    items: CartItem[];
    created: number;
    uid: string;
  }): Cart {
    const subtotal = getCartItemsSubtotal(items);
    const { shippingTotal, itemizedMap } = getShipping(items, shippingAddress);
    // apply shipping cost to each item
    items = items.map((i) => {
      return {
        ...i,
        shipping_cost: itemizedMap.get(i.product_id) ?? 0,
      };
    });
    const product_ids = items.map((i) => i.product_id);
    const last_updated = Date.now();
    const total = subtotal + shippingTotal + (taxInfo?.value ?? 0);
    return {
      created,
      last_updated,
      product_ids,
      shipping: shippingTotal,
      subtotal,
      total,
      uid,
      items,
    };
  }

  async function calculateTax(
    items: CartItem[],
    shippingAddress: AddressDocument
  ) {
    setTaxInfo(() => ({ isCalculating: true }));
    const promises = items.map((i) =>
      getTax({
        product_id: i.product_id,
        seller_id: i.seller_id,
        to_address: shippingAddress!,
        total: i.product_cost * i.qty,
        shipping: i.shipping_cost ?? 0,
      })
    );
    const res = await Promise.all(promises);
    const itemValues = new Map<string, number>();
    let value = 0;
    res.forEach((r) => {
      value += r.amount;
      itemValues.set(r.product_id, r.amount);
    });
    setTaxInfo(() => ({ value, itemValues, isCalculating: false }));
  }
  // #endregion helpers

  function notifyItemsAdded() {
    showToast(<ItemAddedToast />, {
      style: {
        borderRadius: '10px',
        backgroundColor: '#FDF4E9',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#EF9021',
        width: '400px',
      },
    });
  }

  function notifyError(qty: number) {
    const message =
      qty === 0
        ? 'This item is out of stock.'
        : qty === 1
        ? 'There is only 1 available of this item.'
        : `There are only ${qty} available of this item.`;
    showToast(<ErrorToast message={message} />, {
      closeButton: false,
      style: {
        borderRadius: '10px',
        backgroundColor: '#FF5555',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#FF5555',
        width: '400px',
      },
    });
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        addCoupons,
        canAddProductToCart,
        cart,
        cartOpen,
        clearCart,
        coupons,
        decreaseCartQty,
        getDiscount,
        getItemCount,
        getItemQty,
        handleShippingRateSelected,
        increaseCartQty,
        realTimeProducts,
        removeCoupon,
        removeFromCart,
        setCartOpen,
        shippingSelectionComplete: shippingSelectionComplete(
          cart,
          selectedShippingRates
        ),
        selectedShippingRates,
        taxInfo,
        updateShippingAddress,
        calculateTax,
      }}
    >
      <ShoppingCart />
      {children}
    </ShoppingCartContext.Provider>
  );
}
