import Banner from '@ui/Banner';

interface CategorySkeletonProps {
  className?: string;
  loading?: boolean;
}

const ShimmerCard = () => (
  <div className="flex max-w-[34.3rem] flex-col gap-[1.2rem] sm:max-w-[21.1rem]">
    <div className="aspect-square h-[32rem] animate-pulse rounded-[1.6rem] bg-brand-light-gray sm:max-h-[21.2rem]"></div>
    <div className="flex h-[7.7rem] flex-col gap-[.4rem]">
      <div className="h-[3rem] w-11/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
      <div className="h-[3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
    </div>
  </div>
);

const CategorySkeleton = ({}: CategorySkeletonProps) => {
  return (
    <div className="w-full">
      <Banner>&nbsp;</Banner>
      {/* page header */}
      <div className="h-[16rem] bg-brand-black sm:h-[28rem]"></div>
      <div className="mx-4 mt-[6rem] max-w-[120rem] lg:mx-auto">
        <div className="flex gap-[2.4rem]">
          {/* left nav */}
          <div className="hidden h-[103.5rem] w-full max-w-[28.2rem] flex-col gap-[4.3rem] lg:flex">
            <div>
              <div className="mb-[1.9rem] h-[2.2rem] w-full animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
              <div className="flex flex-col gap-[1.2rem]">
                <div className="h-[2rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
              </div>
            </div>
            <div>
              <div className="mb-[1.9rem] h-[2.2rem] w-full animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
              <div className="flex justify-between gap-[1.2rem]">
                <div className="h-[4.4rem] w-[13rem] animate-pulse rounded-full bg-brand-light-gray" />
                <div className="h-[4.4rem] w-[13rem] animate-pulse rounded-full bg-brand-light-gray" />
              </div>
            </div>
            <div>
              <div className="mb-[1.9rem] h-[2.2rem] w-full animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
              <div className="flex flex-col gap-[1.2rem]">
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
              </div>
            </div>
            <div>
              <div className="mb-[1.9rem] h-[2.2rem] w-full animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
              <div className="flex flex-col gap-[1.2rem]">
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
                <div className="h-[2.3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
              </div>
            </div>
          </div>
          {/* top bar and results */}
          <div className="flex w-full max-w-[89.6rem] flex-col gap-[1.2rem]">
            <div className="flex h-[4.4rem] w-full justify-between">
              <div className="h-full w-1/6 animate-pulse rounded-full bg-brand-light-gray" />
              <div className="h-full w-1/3 animate-pulse rounded-full bg-brand-light-gray" />
            </div>
            <div className="h-min-content mt-[4rem] flex flex-col items-center gap-[1.6rem] sm:flex-row sm:flex-wrap sm:items-start sm:justify-center sm:overflow-auto">
              <ShimmerCard />
              <ShimmerCard />
              <ShimmerCard />
              <ShimmerCard />
              <ShimmerCard />
              <ShimmerCard />
              <ShimmerCard />
              <ShimmerCard />
              <ShimmerCard />
              <ShimmerCard />
              <ShimmerCard />
              <ShimmerCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorySkeleton;
