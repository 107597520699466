import { BrandObj } from 'pages/api/brands';
import { BRAND_ROUTE_MAP } from './maps/brands';
import { FAQTopic } from './maps/faq';
import { Brand } from './types/brands';

export function thumbify(path: string) {
  const parts = path.replace(/^\/|\/$/g, '').split('/');
  const ext = parts.pop();
  return `${parts.join('/')}/thumb@500_${ext}`;
}

export function getCdnImagePath(remotePath: string) {
  const fileType = '.jpeg';
  const full = `${remotePath}${fileType}`;
  const thumb = `${thumbify(remotePath)}${fileType}`;
  return { full, thumb };
}

const CDN_HOST = process.env.NEXT_PUBLIC_IS_STAGING
  ? 'https://storage.googleapis.com/gear-focus-staging.appspot.com/'
  : 'https://gearfocus.b-cdn.net/';
export function getCdnImageUrls(remotePath: string) {
  const { full: fPath, thumb: tPath } = getCdnImagePath(remotePath);
  const full = `${CDN_HOST}${fPath}`;
  const thumb = `${CDN_HOST}${tPath}`;
  return { full, thumb };
}

export function getStoragePathFromCdnUrl({
  thumb,
  full,
}: {
  thumb: string;
  full: string;
}) {
  if (thumb.startsWith(CDN_HOST)) thumb = thumb.replace(CDN_HOST, '');
  if (full.startsWith(CDN_HOST)) full = full.replace(CDN_HOST, '');
  return { thumb, full };
}

export function getTopBrandUrl(b: Brand) {
  const slug = BRAND_ROUTE_MAP[b];
  const url = `/brands/${slug}`;
  return url;
}

export function getProductUrl(slug: string) {
  slug = slug.replaceAll('+', '%2B').replaceAll(',', '%2C');
  return `/products/${slug}`;
}

export function encodeQueryData(
  data: Record<string, string | string[] | number | undefined>
) {
  const ret = [];
  for (const d in data) {
    const value = data[d];
    if (Array.isArray(value)) {
      for (const v of value) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(v));
      }
    } else {
      if (value)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(value));
    }
  }
  return ret.join('&');
}

export function getQuestionSlug(q: string) {
  return q
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/&/g, 'and')
    .replace(/\?/g, '');
}

export function getTopicSlug(topic: FAQTopic) {
  return topic.toLowerCase().replace(/ /g, '-').replace(/&/g, 'and');
}

export function getFaqUrl(topic?: FAQTopic, question?: string) {
  if (!topic) return '/faq';
  if (!question) return `/faq/${getTopicSlug(topic)}`;
  return `/faq/${getTopicSlug(topic)}/${getQuestionSlug(question)}`;
}

export function getBrandUrl(b: BrandObj) {
  const slug =
    b.name
      .toLowerCase()
      .trim()
      .replace(/ /g, '-')
      .replace('ö', 'o')
      .replace('é', 'e')
      .replace(/[^a-zA-Z0-9-]/g, '')
      .replace('--', '-') +
    '-' +
    b.old_id;
  return `/brands/${slug}`;
}

export const slugify = (text: string, id?: string): string => {
  const slug = text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .slice(0, 60);
  return id ? `${slug}-${id.slice(0, 5)}` : slug;
};
