import { createContext, useCallback, useContext } from 'react';
import {
  toast,
  ToastContainer,
  ToastOptions,
  ToastPosition,
} from 'react-toastify';
import CloseButton from '@c/icons/buttons/CloseButton';

interface ToastContext {
  showToast: (content: JSX.Element, options?: ToastOptions<Record<string, unknown>>) => void;
  showSuccessToast: (text: string, position?: ToastPosition) => void;
  showErrorToast: (text: string, position?: ToastPosition) => void;
}
const ToastContext = createContext({} as ToastContext);
export function useToastContext() {
  return useContext(ToastContext);
}

const CustomCloseButton = () => {
  return (
    <div className="ml-[1.6rem] mr-[1.6rem] flex flex-col items-center justify-center">
      <CloseButton color="brand-secondary" />
    </div>
  );
};
export function ToastProvider({ children }: any) {
  const showToast = useCallback(
    (content: JSX.Element, options?: ToastOptions<Record<string, unknown>> | undefined) => {
      toast(content, options);
    },
    []
  );

  const showSuccessToast = (
    text: string,
    position: ToastPosition = 'top-center'
  ) => {
    toast.success(text, {
      position,
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      theme: 'light',
      closeButton: <CustomCloseButton />,
    });
  };

  const showErrorToast = (
    text: string,
    position: ToastPosition = 'top-center'
  ) => {
    toast.error(text, {
      position,
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      theme: 'light',
      closeButton: <CustomCloseButton />,
    });
  };

  return (
    <ToastContext.Provider
      value={{
        showToast,
        showSuccessToast,
        showErrorToast,
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={<CustomCloseButton />}
      />
      {children}
    </ToastContext.Provider>
  );
}
