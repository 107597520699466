import FormTextarea from '@c/forms/controls/FormTextarea';
import { MessageIcon } from '@c/icons';
import ProductInfo from '@c/products/ProductInfo';
import { useQuery } from '@tanstack/react-query';
import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import {
  createChat,
  sendMessageToChat,
  redactPhonesAndEmailsFromStr
} from '@util/firestore/messages/messages.service';
import { getProductById } from '@util/firestore/products';
import { getUserDocument } from '@util/firestore/users';
import useRealtimeChats from '@util/hooks/useRealtimeChats';
import { formatPhoneNumber, isMobile } from '@util/index';
import {
  ChatDocument,
  ChatDocumentSchema,
  ChatMessagesDocumentSchema,
} from '@util/types/firestore/chat';
import { OrderDocument } from '@util/types/firestore/order';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { useRouter } from 'next/router';
import React from 'react';
import BaseModal from './BaseModal';

interface ChatModalProps {
  isOpen: boolean;
  dismiss: () => void;
  recipientUid: string;
  productId?: string;
  order?: OrderDocument;
}

function ChatModal({
  isOpen,
  dismiss,
  recipientUid,
  productId,
  order,
}: ChatModalProps) {
  const { data: recipient, isLoading } = useQuery(['user', recipientUid], () =>
    getUserDocument({ uid: recipientUid })
  );
  const { data: product } = useQuery(
    ['product', productId],
    () => getProductById(productId!),
    {
      enabled: !!productId,
    }
  );
  const { userDoc } = useAuth();
  const { realtimeChats } = useRealtimeChats(userDoc?.uid);

  const [message, setMessage] = React.useState('');
  const { openChatDrawer } = useChat();
  const router = useRouter();

  const getSellerId = () => {
    // if productid, check if user is seller or buyer
    if (productId) {
      return product?.seller_id === userDoc?.uid ? userDoc?.uid : recipientUid;
    }
    // if orderid, check if user is seller or buyer
    if (order) {
      return order.buyer_id === userDoc?.uid ? recipientUid : userDoc?.uid;
    }
    // if no productid or orderid, just return recipient id
    return recipientUid;
  };

  function createAndSendMessage() {
    if (!userDoc) {
      router.push('/login');
      return;
    }
    if (!message) return;
    // const redactedMessage = userDoc?.roles?.admin
    //   ? message
    //   : redactPhonesAndEmailsFromStr(message);
    const messageDoc = ChatMessagesDocumentSchema.parse({
      uid: userDoc?.uid ?? '',
      content: message,
      created_at: Date.now(),
    });
    // Two cases: chat exists or chat doesn't exist
    // If chat exists, just send the message
    const existingRegularChat = realtimeChats?.find(
      (chat) => chat.uids?.includes(recipientUid) && !productId && !order
    );
    const existingProductChat = realtimeChats?.find(
      (chat) => chat.product_id && chat.product_id === productId
    );
    const existingOrderChat = realtimeChats?.find(
      (chat) =>
        chat.order_id &&
        chat.order_id === order?.id &&
        chat.seller_id !== process.env.NEXT_PUBLIC_SUPPORT_ID &&
        chat.buyer_id !== process.env.NEXT_PUBLIC_SUPPORT_ID
    );

    const existingChat =
      existingRegularChat || existingProductChat || existingOrderChat;

    let chatToOpen = existingChat;
    if (existingChat) {
      sendMessageToChat(messageDoc, existingChat);
    } else {
      // If chat doesn't exist, create the chat and with the message
      const getBuyerId = () => {
        // if productid, check if user is seller or buyer
        if (productId) {
          return product?.seller_id === userDoc?.uid
            ? recipientUid
            : userDoc?.uid;
        }
        // if orderid, check if user is seller or buyer
        if (order) {
          return order.buyer_id === userDoc?.uid ? userDoc?.uid : recipientUid;
        }
        // if no productid or orderid, just return user id
        return userDoc?.uid;
      };

      messageDoc.content = redactPhonesAndEmailsFromStr(messageDoc.content)

      const chatDoc: ChatDocument = ChatDocumentSchema.parse({
        uids: [userDoc?.uid ?? '', recipientUid],
        buyer_id: getBuyerId() ?? '',
        seller_id: getSellerId() ?? '',
        order_id: order?.id ?? '',
        messages: [messageDoc],
        product_id: productId ?? '',
        created_at: Date.now(),
        unread: {
          [recipientUid]: true,
          [userDoc?.uid ?? '']: false,
        },
      });
      if (userDoc?.uid && userDoc.uid !== process.env.STAGING_ID) {
        chatDoc.unread = { [recipientUid]: true, [userDoc.uid]: false };
      }
      createChat(chatDoc);
      chatToOpen = chatDoc;
    }
    dismiss();

    if (isMobile()) router.push('/dashboard/messages');
    else if (chatToOpen) openChatDrawer(chatToOpen);
  }

  return (
    <BaseModal
      isOpen={isOpen}
      dismiss={dismiss}
      title={
        <h2 className="flex items-center gap-[1.6rem] text-[2.4rem] font-semibold leading-[3.2rem] text-brand-secondary">
          <MessageIcon color="#242E5F" width={32} height={32} />
          Send Message <br></br>
        </h2>
      }
    >
      <div className="flex w-[36rem] flex-col gap-[2.4rem] rounded-[1rem]">
        <div className="flex flex-col  gap-[0.8rem]">
          <h3 className="flex items-center gap-[1.6rem] text-[1.6rem] font-semibold leading-[2.4rem] text-brand-light-black">
            <span>To: </span>
            {isLoading ? (
              '...'
            ) : (
              <Button
                leadingIcon={<Avatar user={recipient!} size="small" />}
                type="text"
                text={recipient?.username}
                noXPadding
                href={`/shop-seller/${recipient?.username_slug}`}
                height="extraSmall"
              />
            )}
          </h3>
          {recipient &&
            getSellerId() === recipient.uid &&
            recipient.uid !== process.env.NEXT_PUBLIC_SUPPORT_ID &&
            !recipient.account_id && (
              <p className="text-xl italic">
                Response times for this seller might take longer than usual
              </p>
            )}
          {userDoc?.roles?.admin && (
            // recipient's phone and email
            <div className="flex flex-col items-start gap-[0.8rem]">
              <h3 className="text-center text-[1.6rem] font-semibold leading-[2.4rem] text-brand-light-black">
                Email: <span className="font-normal">{recipient?.email}</span>
              </h3>
              <h3 className="text-center text-[1.6rem] font-semibold leading-[2.4rem] text-brand-light-black">
                Phone:{' '}
                <span className="font-normal">
                  {formatPhoneNumber(
                    recipient?.phone || recipient?.addresses?.[0]?.phone || ''
                  )}
                </span>
              </h3>
            </div>
          )}
        </div>
        {product && (
          <div className="w-full pl-1">
            {' '}
            <ProductInfo product={product} />{' '}
          </div>
        )}
        <div className="w-full">
          <FormTextarea
            placeholder="Send message to seller"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="flex items-center justify-center gap-[1.6rem] font-semibold text-brand-secondary">
          <Button
            type="secondary"
            text="Send"
            onClick={() => createAndSendMessage()}
          />
        </div>
      </div>
    </BaseModal>
  );
}

export default ChatModal;
