import SafeImage from '@ui/SafeImage';

import { formatTimestamp } from '@util/index';
import { ChatDocument, ChatMessageDocument } from '@util/types/firestore/chat';
import { useAuth } from 'context/AuthContext';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProductById, updateProductDoc, updateStatus } from '@util/firestore/products';
import { updateChat } from '@util/firestore/messages';

import RenewListingModal from '@c/modals/RenewListingModal';

interface MessageBubbleProps {
  chat: ChatDocument;
  messages: ChatMessageDocument[] | []
}

interface ActionButtonProps {
  title: string;
  onClick: () => void;
  disabled: boolean;
  selected: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({ title, onClick, disabled, selected }) => (
  <span
    className={`
      ${disabled ? 'text-brand-gray font-regular' : 'my-5 select-none cursor-pointer rounded-full border-2 border-brand-secondary text-brand-secondary hover:bg-brand-secondary hover:text-brand-white'}
      ${selected ? 'font-semibold' : ''}
      text-[1.5rem] text-center block py-2 max-w-[25rem] mx-auto
    `}
    title={title}
    onClick={onClick}
  >
    {title}
  </span>
);

export default function MessageBubble({
  chat,
  messages
}: MessageBubbleProps) {
  
  const [modelOpen, setModal] = useState<boolean>(false);
  //const [days, setDays] = useState<number>(0);

  const { userDoc } = useAuth();
  const { data: productDoc } = useQuery({
    queryKey: ['product', chat.product_id],
    queryFn: async () => chat.product_id ? await getProductById(chat.product_id) : null,
    enabled: !!chat.product_id,
  });

  const RespondToRenewalAction = async (message: ChatMessageDocument, index: number, reason: string) => {
    if (!productDoc || !chat.messages || !message.action) return;

    const updatedMessage = {
      ...message,
      action: { type: message.action.type, disabled: true, click: reason }
    };
  
    const updatedMessages = [
      ...chat.messages.slice(0, index),
      updatedMessage,
      ...chat.messages.slice(index + 1)
    ];

    const updatedChat = { ...chat, messages: updatedMessages };
    await updateChat(updatedChat);

    await updateStatus(productDoc.id, { created_at: Date.now(), status: reason})
    await updateProductDoc({...productDoc, out_of_stock: true })
  }
  
  const formatAction = (message: ChatMessageDocument, index: number) => {
    if (productDoc && (message.action && message.action.type === 'renewal')) {
      return (
        <div className="mt-[1rem]">
          <ActionButton
            title="Renew Listing"
            onClick={() => setModal(true)}
            disabled={message.action.disabled}
            selected={message.action.click === "Renew Listing"}
          />
          <ActionButton
            title="Sold Elsewhere"
            onClick={() => RespondToRenewalAction(message, index, "Sold Elsewhere")}
            disabled={message.action.disabled}
            selected={message.action.click ===  "Sold Elsewhere"}
          />
          <ActionButton
            title="I Decide Not To Sell"
            onClick={() => RespondToRenewalAction(message, index, "Decided Not To Sell")}
            disabled={message.action.disabled}
            selected={message.action.click === 'Decided Not To Sell'}
          />
          
          <RenewListingModal product={productDoc} chat={chat} index={index} isOpen={modelOpen} dismiss={() => { setModal(false) }} />
        </div>
      )
    }

    return (
      <>
      </>
    )
  };

  return (
    <div className="grid">
      {messages && messages.map((message, index) => (
        <div className="max-w-[80%] py-[1.6rem]" key={`bot-message-${index}`}>
          <div>
          <div className="flex w-full items-start gap-[0.8rem] p-[1.6rem] rounded-chatLeft bg-brand-lightest-gray text-brand-black">
              <div className="relative rounded-full overflow-hidden flex-shrink-0 h-[3.2rem] w-[3.2rem]">
                <SafeImage
                  src="https://gearfocus.b-cdn.net/gearbot.png"
                  alt="GearBot Automated Message"
                  width={40}
                  height={40}
                  unoptimized
                  fill
                  key="https://gearfocus.b-cdn.net/gearbot.png"
                  style={{ objectFit: 'cover' }}
                  itemProp="image"
                />
              </div>
              <div className="gap-[0.4rem] text-left max-w-[calc(100% - 3.2rem)]">
                <p className="text-[1.3rem]">{message.content}</p>
                {formatAction(message, index) }
              </div>
          </div>
          {!!message.created_at && (
            <p className="text-right text-[1rem] text-brand-gray">
              {formatTimestamp(message.created_at)}
            </p>
          )}
          </div>
        </div>
      ))}
    </div>
  );
}
