import { cva, VariantProps } from 'class-variance-authority';
import { CloseIcon } from '..';

interface CloseButtonProps extends VariantProps<typeof closeButtonStyles> {
  onClick?: () => void;
}

const closeButtonStyles = cva(
  'transition-colors flex items-center justify-center w-[2.4rem] h-[2.4rem]',
  {
    variants: {
      color: {
        'brand-secondary':
          'text-brand-secondary hover:border-2 hover:border-brand-secondary rounded-[10px]',
      },
    },
    defaultVariants: {
      color: 'brand-secondary',
    },
  }
);

const CloseButton = ({ onClick, color }: CloseButtonProps) => {
  return (
    <>
      <button onClick={onClick} className={closeButtonStyles({ color })}>
        <CloseIcon width={20} height={20} />
      </button>
    </>
  );
};

export default CloseButton;
