import { functions, httpsCallable } from '@util/firebase';
import { PaymentDocument } from '@util/types/firestore/payment';
import Stripe from 'stripe';
import {
  CreatePaymentArgs,
  ExternalAccount,
  PayoutResponse,
  StripeAccountResponse,
  StripeTransaction,
  UpdatePaymentArgs,
} from './payments.types';

interface PaymentIntent {
  data: string;
  pi_id: string;
}

export async function createPayment(
  args: CreatePaymentArgs
): Promise<PaymentIntent> {
  const res = await httpsCallable<CreatePaymentArgs, PaymentIntent>(
    functions,
    'createPaymentIntent'
  )(args);
  return res.data;
}

export async function updatePayment(
  args: UpdatePaymentArgs
): Promise<PaymentIntent | null> {
  try {
    const res = await httpsCallable<UpdatePaymentArgs, PaymentIntent>(
      functions,
      'updatePaymentIntent'
    )(args);
    return res.data;
  } catch (e) {
    console.log("error with payment intent", e)
    return null
  }
  
}

type PayWithBalanceArgs = Omit<UpdatePaymentArgs, 'pi_id'>;
export async function payWithBalance(
  args: PayWithBalanceArgs
): Promise<Stripe.Response<Stripe.Charge>> {
  const res = await httpsCallable<
    PayWithBalanceArgs,
    Stripe.Response<Stripe.Charge>
  >(
    functions,
    'payWithBalance'
  )(args);
  return res.data;
}

export async function createSetupIntent(): Promise<PaymentIntent> {
  const res = await httpsCallable<void, PaymentIntent>(
    functions,
    'stripe-createSetupIntent'
  )();
  return res.data;
}

interface StripePaymentMethodResponse {
  data: Array<{
    id: string;
    card: {
      last4: string;
      brand: string;
      exp_month: number;
      exp_year: number;
    };
    billing_details: {
      name: string;
    };
  }>;
}

export async function getPaymentMethods(): Promise<PaymentDocument[]> {
  // Making an HTTPS call to a cloud function named 'getPaymentMethods'
  // The await keyword is used to wait for the asynchronous call to resolve
  const res = await httpsCallable<void, StripePaymentMethodResponse>(
    functions,
    'getPaymentMethods'
  )();

  // Mapping the response data to an array of PaymentDocument objects
  // Each item in the response data is transformed to fit the PaymentDocument structure
  const cards: PaymentDocument[] = res.data.data.map((pm) => ({
    id: pm.id,
    last4: pm.card.last4,
    brand: pm.card.brand,
    name: pm.billing_details.name,
    expMonth: `${pm.card.exp_month}`,
    expYear: `${pm.card.exp_year}`,
    type: 0, // card type
    saved: true
  }));

  // Returning the array of PaymentDocument objects
  return cards;
}

interface DeleteCardArgs {
  payment_method: string;
}

export async function deleteCard(pm_id: string): Promise<Record<string, unknown>> {
  const res = await httpsCallable<DeleteCardArgs, Record<string, unknown>>(
    functions,
    'deleteCard'
  )({
    payment_method: pm_id,
  });
  return res.data;
}

export async function getBalance() {
  const res = await httpsCallable<
    void,
    { available_balance: number; actual_pending: number; currency: string }
  >(
    functions,
    'getBalance'
  )();
  return res.data;
}

export async function getTransactionHistory() {
  const res = await httpsCallable<void, { data: StripeTransaction[] }>(
    functions,
    'listTransactions'
  )();
  return res.data?.data ?? [];
}

interface PayoutSellerArgs {
  amount: number;
  method: 'standard' | 'instant';
  destination: string;
  currency: string;
  requirements?: { [x: string]: { [x: string]: string } };
}

export async function payoutSeller(
  amount: number,
  method: 'standard' | 'instant',
  destination: string,
  currency = 'usd',
  requirements?: { [x: string]: { [x: string]: string } }
): Promise<PayoutResponse> {
  const res = await httpsCallable<PayoutSellerArgs, PayoutResponse>(
    functions,
    'payoutSeller'
  )({
    amount,
    method,
    destination,
    currency,
    requirements,
  });
  return res.data;
}

export async function getStripeAccount() {
  const res = await httpsCallable<void, StripeAccountResponse>(
    functions,
    'getSeller'
  )();
  return res.data;
}

interface UpdateSellerAccountArgs {
  requirements: Record<string, string>[];
}

export async function updateSellerAccount(
  requirements: Record<string, string>[]
) {
  const res = await httpsCallable<UpdateSellerAccountArgs, { status: boolean }>(
    functions,
    'updateStripeAccount'
  )({
    requirements,
  });
  return res.data;
}

interface PayoutMethodArgs {
  account_id: string;
  id: string;
}

export async function addSellerPayoutMethod(account_id: string, id: string) {
  const res = await httpsCallable<PayoutMethodArgs, ExternalAccount>(
    functions,
    'addSellerPayoutMethod'
  )({ account_id, id });
  return res.data;
}

export async function removeSellerPayoutMethod(account_id: string, id: string) {
  const res = await httpsCallable<PayoutMethodArgs, ExternalAccount>(
    functions,
    'removeExternalAccount'
  )({ account_id, id });
  return res.data;
}

interface ChargeAccountArgs {
  account_id: string;
  amount: number;
  description: string;
}

export async function chargeAccount(
  account_id: string,
  amount: number,
  description = ''
) {
  const res = await httpsCallable<ChargeAccountArgs, string>(
    functions,
    'chargeAccount'
  )({ account_id, amount, description });
  // returns the charge_id
  return res.data;
}

interface ChargeCreditCardArgs {
  paymentMethodId: string;
  customerId: string;
  amount: number;
  description: string;
}

export async function chargeCreditCard(
  paymentMethodId: string,
  customerId: string,
  amount: number,
  description = ''
) {
  const res = await httpsCallable<ChargeCreditCardArgs, string>(
    functions,
    'stripe-chargeCreditCard'
  )({ paymentMethodId, customerId, amount, description });
  // returns the charge_id
  return res.data;
}

interface CreateTransferArgs {
  account_id: string;
  amount: number;
  description: string;
}

export async function createTransfer(
  account_id: string,
  amount: number,
  description = ''
) {
  const res = await httpsCallable<CreateTransferArgs, string>(
    functions,
    'createTransfer'
  )({ account_id, amount, description });
  // returns the charge_id
  return res.data;
}

// type Status =
// | 'canceled'
// | 'processing'
// | 'requires_action'
// | 'requires_capture'
// | 'requires_confirmation'
// | 'requires_payment_method'
// | 'succeeded'
// | 'failed'
// | 'pending';
interface GetPaymentInfoArgs {
  payment_id: string;
}

export async function getPaymentInfo(payment_id: string) {
  const res = await httpsCallable<GetPaymentInfoArgs, Stripe.Charge | Stripe.PaymentIntent>(
    functions,
    'getPaymentInfo'
  )({ payment_id });
  return res.data;
}
