import { z } from 'zod';

export const productSortOptions = z.union([
  z.literal('newest'),
  z.literal('priceAsc'),
  z.literal('priceDesc'),
]);
export const productLimitOptions = z.union([
  z.literal(12),
  z.literal(24),
  z.literal(48),
]);
export const brandFilterSchema = z.union([
  z.literal('apple'),
  z.literal('arri'),
  z.literal('benq'),
  z.literal('huawei'),
  z.literal('nubear'),
  z.literal('sandisk'),
  z.literal('sigma'),
  z.literal('apple'),
  z.literal('dji'),
  z.literal('nikon'),
  z.literal('canon'),
  z.literal('sony'),
  z.literal('panasonic'),
  z.literal('fujifilm'),
  z.literal('gopro'),
  z.literal('kodak'),
  z.literal('leica'),
  z.literal('red'),
  z.literal('samsung'),
  // z.literal('other'),
]);

export const conditionFilterSchema = z.union([
  z.literal('new'),
  z.literal('refurbished'),
  z.literal('unew'),
  z.literal('uexcellent'),
  z.literal('ugood'),
  z.literal('upoor'),
]);

export const curatedListSchema = z.object({
  content_buttom: z.string(),
  content_type: z.string(),
  created: z.number(),
  header: z.string(),
  id: z.string(),
  metadata: z.object({
    description: z.string(),
    keywords: z.string(),
    title: z.string(),
  }),
  name: z.string()
});

export const getProductsByCategoryArgsSchema = z.object({
  category: z.string(),
  category1: z.string().optional(),
  category2: z.string().optional(),
  keyword: z.string().optional(),
  is_featured: z.boolean().optional(),
  page: z.number(),
  minPrice: z.number().optional().nullable(),
  maxPrice: z.number().optional().nullable(),
  limit: productLimitOptions,
  sort: productSortOptions,
  brands: z.array(z.string()).optional().nullable(),
  conditions: z.array(z.string()).optional().nullable(),
});

export const getProductArgs = z.object({
  slug: z.string().optional(),
  id: z.string().optional(),
});

export type BrandFilter = z.infer<typeof brandFilterSchema>;
export type ConditionFilter = z.infer<typeof conditionFilterSchema>;
export type ProductLimitOption = z.infer<typeof productLimitOptions>;
export type ProductSortOption = z.infer<typeof productSortOptions>;
export type GetProductArgs = z.infer<typeof getProductArgs>;
export type GetProductsByCategoryArgs = z.infer<
  typeof getProductsByCategoryArgsSchema
>;
export type CuratedListDocument = z.infer<typeof curatedListSchema>;