import { cva, cx, VariantProps } from 'class-variance-authority';

const styles = cva(
  cx([
    'text-center w-full lg:min-h-[5.52rem] flex flex-wrap items-center justify-center',
    'text-white text-[1.5rem] sm:text-[1.8rem] font-medium sm:font-semibold py-[1.6rem]',
  ]),
  {
    variants: {
      color: {
        light: 'bg-brand-lighter-purple',
        normal: 'bg-brand-lightest-black',
        brand: 'bg-brand-secondary',
      },
      rounded: {
        true: 'rounded-[1rem] overflow-clip',
      },
    },
    defaultVariants: {
      color: 'normal',
      rounded: false,
    },
  }
);

interface BannerProps extends VariantProps<typeof styles> {
  children?: React.ReactNode;
  color?: 'light' | 'normal' | 'brand';
  rounded?: boolean;
}

const Banner = ({ children, color, rounded }: BannerProps) => {
  return (
    <>
      <div className={styles({ color, rounded })}>{children}</div>
    </>
  );
};

export default Banner;
