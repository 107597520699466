import { CancelIcon } from '@c/icons';
import Button from '@ui/Button';
import React from 'react';
import BaseModal from './BaseModal';

interface ConfirmModalProps {
  title: string;
  body: string;
  children?: React.ReactNode;
  buttonText?: string;
  isOpen: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  btnType?: 'success' | 'decline';
  dismiss: (confirm: boolean) => void;
  disabled?: boolean;
}

function ConfirmModal({
  title,
  body,
  buttonText = 'Ok',
  isOpen,
  icon = <CancelIcon />,
  btnType = 'decline',
  dismiss,
  loading = false,
  disabled,
  children,
}: ConfirmModalProps) {
  return (
    <BaseModal isOpen={isOpen} hideCloseIcon>
      <div className="flex w-[36rem] flex-col items-center gap-[2.4rem] rounded-[1rem]">
        {icon}
        <div className="flex flex-col items-center gap-[0.8rem]">
          <h2 className="text-center text-[2.4rem] font-semibold leading-[3.2rem] text-brand-light-black">
            {title}
          </h2>
          <span className="text-center leading-[2.2rem] text-brand-gray">
            {!loading ? body : 'Submitting! Do not close this window.'}
          </span>
        </div>
        {children}

        <div className="mt-[5rem] flex items-center justify-center font-semibold text-brand-secondary">
          {!loading && (
            <Button type="text" text="Cancel" onClick={() => dismiss(false)} />
          )}
          <Button
            buttonType="submit"
            type={btnType}
            disabled={disabled}
            text={buttonText}
            loading={loading}
            onClick={() => {
              if (!loading) dismiss(true);
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
}

export default ConfirmModal;
