import { ChatDocument, ChatDocumentSchema } from '@util/types/firestore/chat';
import { onSnapshot } from 'firebase/firestore';
import { useState, useMemo, useEffect } from 'react';
import { getChatsByChatIds } from '@util/firestore/messages/';

const useRealtimeChatsByChatIds = (chatIds?: string[]) => {
  const [realtimeChats, setChats] = useState<ChatDocument[] | null>(null);
  const chatsQuery = useMemo(
    () => getChatsByChatIds(chatIds ?? ['n/a']),
    [chatIds]
  );

  useEffect(() => {
    const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
      const realTimeData = snapshot.docs.map((doc) => {
        const data = doc.data();
        try {
          return ChatDocumentSchema.parse(data);
        } catch (e) {
          console.error('Invalid chat document:', e);
          return null;
        }
      }).filter((chat): chat is ChatDocument => chat !== null);
      setChats(realTimeData);
    });
    return () => {
      unsubscribe();
    };
  }, [chatsQuery]);

  return {
    realtimeChats,
  };
};

export default useRealtimeChatsByChatIds;
