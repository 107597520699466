import Image, { ImageProps } from 'next/image';
import { useState } from 'react';

interface SafeImageProps extends ImageProps {
  alt: string;
  height?: number;
  width?: number;
  src: string;
  fallbackSrc?: string;
  classes?: string;
  priority?: boolean;
}

const ERROR_MAX_WIDTH = 96;

const SafeImage = ({
  src,
  height = 144,
  width = 144,
  alt,
  fallbackSrc = '/not_found.svg',
  fill = false,
  classes = '',
  priority = false,
  ...imageProps
}: SafeImageProps) => {
  const [imgProps, setImgProps] = useState({
    width,
    height,
    src,
  });

  const handleOnError = () => {
    setImgProps({
      width: Math.min(ERROR_MAX_WIDTH, width),
      height: Math.min(ERROR_MAX_WIDTH, width),
      src: fallbackSrc || '/not_found.svg',
    });
  };
  const dimmensionProps = fill
    ? { fill: true }
    : { height: imgProps.height, width: imgProps.width };
  return (
    <Image
      alt={alt}
      src={imgProps.src || '/not_found.svg'}
      onError={handleOnError}
      {...dimmensionProps}
      className={classes}
      priority={priority}
      {...imageProps}
      loading={priority ? 'eager' : 'lazy'}
      itemProp="image"
    />
  );
};

export default SafeImage;
