import { HandIcon } from '@c/icons';
import ConfirmModal from '@c/modals/ConfirmModal';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import Chip from '@ui/Chip';
import SafeImage from '@ui/SafeImage';
import { ProductDocument } from '@util/types/firestore/products';
import { useAuth } from 'context/AuthContext';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { acceptOffer, getOffer, setOfferState } from '@util/firestore/offers/';
import { getProductById } from '@util/firestore/products';
import { useRouter } from 'next/router';
import { formatCurrency } from '@util/index';
import { logEvent, getItems } from '@util/analytics';
import { logError } from '@util/logError';
import { getProductUrl } from '@util/urlHelpers';

interface MessageOfferProps {
  offer_id?: string;
}

export default function MessageOffer({ offer_id }: MessageOfferProps) {
  const [loadingOfferSubmit, setLoadingOfferSubmit] = React.useState(false);

  const { userDoc } = useAuth();
  const router = useRouter();
  const [countdownTimer, setCountdownTimer] = React.useState<number | null>(
    null
  );
  const queryClient = useQueryClient();

  const timeToString = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours}h : ${minutes}m : ${
      seconds < 10 ? '0' + seconds : seconds
    }s`;
  };

  const { data: offer } = useQuery({
    queryKey: ['offer', offer_id],
    queryFn: async () => offer_id ? await getOffer(offer_id) : null,
    enabled: !!offer_id,
  });
  const { data: product } = useQuery({
    queryKey: ['product', offer?.product_id],
    queryFn: async () => offer?.product_id ? await getProductById(offer.product_id) : null,
    enabled: !!offer?.product_id,
  });

  useEffect(() => {
    if (!offer?.time_remaining || offer.state || countdownTimer) return;
    const offerExpirationDate = new Date(offer.time_remaining).getTime();
    // get the time remaining in seconds
    const timeRemaining = Math.floor(
      (offerExpirationDate - new Date().getTime()) / 1000
    );

    setCountdownTimer(timeRemaining);
    const interval = setInterval(() => {
      setCountdownTimer((prev) => {
        if (prev && prev > 0) {
          return prev - 1;
        }
        clearInterval(interval);
        return 0;
      });
    }, 1000);
  }, [offer, countdownTimer]);

  const getChip = (state: number) => {
    switch (state) {
      case 0:
        return (
          <Chip text={timeToString(countdownTimer ?? 0)} color="primary" />
        );
      case 1:
        return <Chip text="Accepted" color="success" />;
      case 2:
        return <Chip text="Declined" color="error" />;
      case 3:
        return <Chip text="Cancelled" color="gray" />;
      default:
        return <Chip text="Pending" color="gray" />;
    }
  };

  const declineOffer = async () => {
    if (!offer_id) return;
    await setOfferState(offer_id, 2);
    queryClient.invalidateQueries({
      queryKey: ['offer', offer_id],
    });
    setCountdownTimer(null);
  };

  const acceptThatOffer = async () => {
    if (!offer_id) return;
    setLoadingOfferSubmit(true);
    try {
      const order = await acceptOffer(offer_id);
      const offerQ = queryClient.invalidateQueries({
        queryKey: ['offer', offer_id],
      });
      const orderQ = queryClient.invalidateQueries({
        queryKey: ['orders', userDoc?.uid],
      });
      await Promise.all([offerQ, orderQ]);
      logEvent('purchase', {
        transaction_id: order.id,
        value: order.total,
        currency: 'USD',
        tax: order.tax ?? 0,
        shipping: order.shipping_total ?? 0,
        items: getItems([
          product ??
            ({
              id: offer_id,
              title: offer_id,
              price: order.total,
            } as ProductDocument),
        ]),
      });
      router.push(`/dashboard/orders/${order.id}`);
      setCountdownTimer(null);
    } catch (e) {
      logError(e);
    }
    setLoadingOfferSubmit(false);
    setAcceptIsOpen(false);
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [isAcceptOpen, setAcceptIsOpen] = React.useState(false);

  return (
    <>
      <div className="flex w-full flex-col gap-[1.6rem] p-[1.6rem]">
        {product && offer && (
          <>
            <Link
              href={getProductUrl(product.slug)}
              className="flex min-h-[6.4rem] items-center gap-[1.6rem]"
            >
              <SafeImage
                className="rounded-2xl"
                height={64}
                width={64}
                alt={product.title}
                key={product.images[0]?.thumb}
                src={product.images[0]?.thumb}
              />
              <div className="text-elipsis flex grow flex-col items-start  justify-center gap-[1.6rem] overflow-hidden whitespace-nowrap">
                <h2 className="line-clamp-2 whitespace-pre-wrap font-semibold">
                  {product.title}
                </h2>
                <div className="flex  w-full flex-wrap items-start gap-[0.8rem]">
                  <div className="flex flex-grow items-center">
                    <HandIcon />
                    <div className="font-semibold">
                      <span className="mr-2 font-normal text-brand-dark-gray">
                        Offer price:
                      </span>
                      {formatCurrency(offer.price ?? 0)}
                    </div>
                  </div>
                  {getChip(offer.state)}
                </div>
              </div>
            </Link>
            <div className="flex items-start gap-[0.8rem]">
              {userDoc?.uid === offer?.seller_id && !offer?.state && (
                <div className="flex w-full justify-center gap-[1.6rem]">
                  <Button
                    type="error"
                    text="Decline"
                    width="fixed"
                    onClick={() => setIsOpen(true)}
                  />
                  <Button
                    type="success"
                    width="fixed"
                    text="Accept Offer"
                    onClick={() => setAcceptIsOpen(true)}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <ConfirmModal
        title="Decline Offer?"
        body="Are you sure you want to decline this offer? You can not undo this action."
        buttonText="Decline Offer"
        isOpen={isOpen}
        dismiss={(confirm) => {
          setIsOpen(false);
          if (confirm) declineOffer();
        }}
      />
      <ConfirmModal
        title="Accept Offer?"
        icon={<HandIcon />}
        btnType="success"
        body={`By accepting this offer, you agree to sell this item for $${offer?.total} ($${offer?.price} + $${offer?.shipping_cost} Shipping + $${offer?.tax} Tax). An order will be created and can be viewed in your seller dashboard.`}
        buttonText="Accept Offer"
        isOpen={isAcceptOpen}
        loading={loadingOfferSubmit}
        dismiss={(confirm) => {
          if (confirm) acceptThatOffer();
          else setAcceptIsOpen(false);
        }}
      />
    </>
  );
}
